import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

function MotForm({ page }) {
	const [state, handleSubmit] = useForm("xvoljqqa");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3 " controlId="subject">
						<Form.Control
							value={`${page} Enquiry`}
							className="pb-4 d-none"
							name="subject"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="Subject"
						field="subject"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>NAME*</Form.Label>
						<Form.Control className="pb-4" name="name" required type="text" />
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />

					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label>TELEPHONE NUMBER*</Form.Label>
						<Form.Control
							required
							className="pb-4"
							type="tel"
							name="telephone"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="reg-number">
						<Form.Label>REG NUMBER*</Form.Label>
						<Form.Control
							className="pb-4"
							name="reg-number"
							required
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="reg-number"
						field="reg-number"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="preferred-date-and-time">
						<Form.Label className="text-uppercase">
							Preferred Date and Time*
						</Form.Label>
						<Datetime
							dateFormat="Do MMM YYYY"
							timeFormat="HH:mm"
							inputProps={{
								required: true,
								name: "preferred-date-and-time",
								className: `pb-4 ps-3 form.control new-date w-100`,
							}}
							timeConstraints={{ minutes: { step: 30 } }}
						/>
					</Form.Group>
					<ValidationError
						prefix="preferred-date-and-time"
						field="preferred-date-and-time"
						errors={state.errors}
					/>

					{/* <div
				class="g-recaptcha"
				data-sitekey="6LdpZdQgAAAAAMGxG6mYjIg6joW2j_K9jJxjwnLN"
			></div> */}
					<div className="text-start">
						<Button
							variant="secondary"
							className="btn py-3 px-7 w-100 w-md-auto mt-3"
							style={{ borderRadius: "0px" }}
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							Send
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3 " controlId="subject">
				<Form.Control
					value={`${page} Enquiry`}
					className="pb-4 d-none"
					name="subject"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Subject" field="subject" errors={state.errors} />
			<Form.Group className="mb-3" controlId="name">
				<Form.Label>NAME*</Form.Label>
				<Form.Control className="pb-4" name="name" required type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />

			<Form.Group className="mb-3" controlId="telephone">
				<Form.Label>TELEPHONE NUMBER*</Form.Label>
				<Form.Control required className="pb-4" type="tel" name="telephone" />
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="reg-number">
				<Form.Label>REG NUMBER*</Form.Label>
				<Form.Control className="pb-4" name="reg-number" required type="text" />
			</Form.Group>
			<ValidationError
				prefix="reg-number"
				field="reg-number"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="preferred-date-and-time">
				<Form.Label className="text-uppercase">
					Preferred Date and Time*
				</Form.Label>
				<Datetime
					dateFormat="Do MMM YYYY"
					timeFormat="HH:mm"
					inputProps={{
						required: true,
						name: "preferred-date-and-time",
						className: `pb-4 ps-3 form.control new-date w-100`,
					}}
					timeConstraints={{ minutes: { step: 30 } }}
				/>
			</Form.Group>
			<ValidationError
				prefix="preferred-date-and-time"
				field="preferred-date-and-time"
				errors={state.errors}
			/>

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LdpZdQgAAAAAMGxG6mYjIg6joW2j_K9jJxjwnLN"
			></div> */}
			<div className="text-start">
				<Button
					variant="secondary"
					className="btn py-3 px-7 w-100 w-md-auto mt-3"
					style={{ borderRadius: "0px" }}
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Send
				</Button>
			</div>
		</Form>
	);
}
export default MotForm;
