import React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { SafeHtmlParser } from "./safeHtmlParser";

const Badges = ({ title, text, logos }) => {
  const newText = text || (
    <span>
      <strong>Manufacturer Approved Accident Repair Centre</strong>, we repair
      accident damaged vehicles to <strong>factory standard</strong> using
      genuine parts and leading manufacturers trained technicians.
    </span>
  );

  return (
    <section className="py-5 py-lg-8 bg-white">
      <Container>
        <Row className="  ">
          <Col>
            <h2 className="text-center pb-5 pb-lg-6">
              <SafeHtmlParser htmlContent={title} />
            </h2>
          </Col>
        </Row>
        {logos && logos.length > 0 && (
          <Marquee className="brand-image-container">
            {logos.map((logoItem, i) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={logoItem.vehicleLogoFields.logoUrl}
                key={i}
              >
                <GatsbyImage
                  image={
                    logoItem.vehicleLogoFields.logoImage.node?.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={logoItem.vehicleLogoFields.logoImage.node?.altText}
                  className="w-100 h-100"
                />
              </a>
            ))}
          </Marquee>
        )}
        {/* <Marquee className="brand-image-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://audiapprovedrepair.co.uk/approved-crash-repair-centre-contact-info/50134"
          >
            <GatsbyImage
              image={data.AudiLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Audi Approved Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>

          <a href="#">
            <GatsbyImage
              image={
                data.MercedesLogo?.localFile.childImageSharp.gatsbyImageData
              }
              className="w-100 h-100"
              alt="Mercedes Benz Approved Accident Repair Centre Eastbourne"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://cupraapprovedrepair.co.uk/approved-crash-repair-centre-contact-info/50134"
          >
            <GatsbyImage
              image={data.CupraLogo?.localFile.childImageSharp.gatsbyImageData}
              className="w-100 h-100"
              alt="Cupra Approved Accident Repair Centre Eastbourne"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://volkswagenapprovedrepair.co.uk/approved-crash-repair-centre-contact-info/50134"
          >
            <GatsbyImage
              image={data.VWLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Volkswagen Approved Accident Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>

          <a href="#">
            <GatsbyImage
              image={data.KiaLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Kia Approved Accident Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://seatapprovedrepair.co.uk/approved-crash-repair-centre-contact-info/50134"
          >
            <GatsbyImage
              image={data.SeatLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Seat Approved Accident Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://volkswagen-vansapprovedrepair.co.uk/approved-crash-repair-centre-contact-info/50134"
          >
            <GatsbyImage
              image={data.VWCLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Volkswagen Commercial Vehicles Approved Accident Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://skodaapprovedrepair.co.uk/approved-crash-repair-centre-contact-info/50134"
          >
            <GatsbyImage
              image={data.SkodaLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Skoda Approved Accident Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>

          <a href="#">
            <GatsbyImage
              image={data.CLogo?.localFile.childImageSharp.gatsbyImageData}
              alt="Smart Approved Accident Repair Centre Eastbourne"
              className="w-100 h-100"
            />
          </a>
        </Marquee> */}

        <Row>
          <Col className="text-md-center pt-5">
            <SafeHtmlParser htmlContent={newText} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Badges;
